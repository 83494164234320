//
// dropzone.js
// Theme module
//

import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;
Dropzone.thumbnailWidth = null;
Dropzone.thumbnailHeight = null;

const toggles = document.querySelectorAll('[data-dropzone]');

toggles.forEach((toggle) => {
  let currentFile = undefined;

  const elementOptions = toggle.dataset.dropzone ? JSON.parse(toggle.dataset.dropzone) : {};

  const defaultOptions = {
    previewsContainer: toggle.querySelector('.dz-preview'),
    previewTemplate: toggle.querySelector('.dz-preview').innerHTML,
    init: function () {
      this.on('addedfile', function (file) {
        const maxFiles = elementOptions.maxFiles;

        if (maxFiles == 1 && currentFile) {
          this.removeFile(currentFile);
        }

        currentFile = file;
      });
    },
    // BUGFIX START: transformation of file should not restore EXIF data when orientation is used for resizing
    // https://github.com/dropzone/dropzone/issues/1967
    transformFile(file, done) {
      if (
        (this.options.resizeWidth || this.options.resizeHeight) &&
        file.type.match(/image.*/)
      ) {
        return this.createThumbnail(
          file,
          this.options.resizeWidth,
          this.options.resizeHeight,
          this.options.resizeMethod,
          true,
          (dataUrl, canvas) => {
            if (canvas == null) {
              // The image has not been resized
              return done(file);
            } else {
              let { resizeMimeType } = this.options;
              if (resizeMimeType == null) {
                resizeMimeType = file.type;
              }
              let resizedDataURL = canvas.toDataURL(
                resizeMimeType,
                this.options.resizeQuality
              );
              // Removed restoring of EXIF data on resized image.
              // if (
              //   resizeMimeType === "image/jpeg" ||
              //   resizeMimeType === "image/jpg"
              // ) {
              //   // Now add the original EXIF information
              //   resizedDataURL = ExifRestore.restore(file.dataURL, resizedDataURL);
              // }
              return done(Dropzone.dataURItoBlob(resizedDataURL));
            }
          }
        );
      } else {
        return done(file);
      }
    },
    // BUGFIX END
  };

  const options = {
    ...elementOptions,
    ...defaultOptions,
  };

  // Clear preview
  toggle.querySelector('.dz-preview').innerHTML = '';

  // Init dropzone
  new Dropzone(toggle, options);
});

// Make available globally
window.Dropzone = Dropzone;
